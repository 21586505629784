import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

const Section = ({
  id, title, gradient, fluid, className, children,
}) => {
  const sectionId = `${id}Section`;

  return (
    <section
      id={id}
      aria-labelledby={sectionId}
      className={className}
    >
      <div className={fluid ? 'container-fluid' : 'container'}>
        {title && <Title id={sectionId} gradient={gradient}>{title}</Title>}
        {children}
      </div>
    </section>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  gradient: PropTypes.bool,
  fluid: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.defaultProps = {
  title: undefined,
  gradient: false,
  fluid: false,
  className: undefined,
  children: undefined,
};

export default Section;
