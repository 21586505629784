import React from 'react';
import { Link } from 'react-router-dom';

import styles from './FooterNav.module.scss';

const className = [
  'nav',
  'justify-content-center justify-content-lg-start',
  'flex-column flex-sm-row',
  styles.FooterNav,
].join(' ');

const FooterNav = () => (
  <ul className={className}>
    <li className="nav-item">
      <Link to="/cookie" className="nav-link">Cookie Policy</Link>
    </li>

    <li className="nav-item">
      <Link to="/privacy" className="nav-link">Privacy Policy</Link>
    </li>

    <li className="nav-item">
      <Link to="/terms" className="nav-link">Terms and Conditions</Link>
    </li>
  </ul>
);

export default FooterNav;
