import React from 'react';

import IndexNavbar from '../components/Navbar/IndexNavbar';
import Hero from '../components/Hero';
import HowItWorks from '../components/HowItWorks';
import DemoLink from '../components/DemoLink';
import Form from '../components/Form';

const IndexPage = () => (
  <>
    <IndexNavbar />
    <Hero />
    <HowItWorks />
    <DemoLink />
    <Form />
  </>
);

export default IndexPage;
