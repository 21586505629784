import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = ({ id, gradient, children }) => {
  const className = gradient ? styles.Gradient : styles.Title;
  return (
    <div className="row">
      <div className="col-12 mx-auto" style={{ maxWidth: 740 }}>
        <h2 id={id} className={className}>{children}</h2>
      </div>
    </div>
  );
};

Title.propTypes = {
  id: PropTypes.string.isRequired,
  gradient: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Title.defaultProps = {
  gradient: false,
};

export default Title;
