import { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'bootstrap';

export default function useToggler() {
  const [collapse, setCollapse] = useState();

  useEffect(() => {
    const toggler = document.getElementById('antiCheatNavbar');

    setCollapse(() => {
      return new Collapse(toggler, { toggle: false })
    });
  }, [setCollapse]);

  const toggler = useCallback(() => {
    if (collapse && collapse._isShown()) {
      collapse.toggle();
    }
  }, [collapse]);

  return toggler;
}
