import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import shield from '../../images/shield.svg';
import Section from '../Section';
import './Form.scss';
import './ThankYou.scss';

async function postData(url = '', data = {}, token) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: { 'Content-Type': 'text/plain' },
    redirect: 'follow',
    referrerPolicy: 'origin',
    body: JSON.stringify({ data: data, token: token }),
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const Form = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Execute reCaptcha
    if (!executeRecaptcha) { return; }

    // The value of the input fields when submit is pressed
    const formData = new FormData(e.currentTarget);

    // Fetch reCaptcha token
    const token = await executeRecaptcha('submission');

    // Converting FormData to JSON
    let object = {};

    let url = '/submit';

    formData.forEach((value, key) => {
      object[key] = value;
    });

    const Form = document.getElementById('form');
    const ThankYou = document.getElementById('thank-you');

    // Hide the contact form and display the response message
    Form.style.display = 'none';
    ThankYou.style.display = 'flex';

    postData(url, object, token);
  };

  return (
    <Section
      id="submit"
      title="Submit your demo to check for aimbots"
      className="Form"
      gradient
      fluid
    >
      <div className="row mt-3">
        <div className="col-12">
          <form id="form" onSubmit={handleSubmit}>
            <div className="Form__inner">
              <div className="Form__inner--row">
                <label>Name</label>
                <input type="text" name="name" />
              </div>
              <div className="Form__inner--row">
                <label>E-mail</label>
                <input type="email" name="email" required />
              </div>
              <div className="Form__inner--row">
                <label>Link to demo</label>
                <input type="text" placeholder="steam://rungame/" name="link" />
              </div>
              <div className="Form__inner--row check">
                <input type="checkbox" id="agree" name="privacy-policy" required />
                <label htmlFor="agree">
                  I agree to the
                  {' '}
                  <Link to="/privacy">
                    Privacy Policy
                  </Link>
                </label>
              </div>
              <div className="Form__inner--row submit-container">
                <input type="submit" value="Submit" className="btn" name="submit" />
              </div>
              <div className="Form__inner--row">
                <p>Got questions? <a href="mailto:anticheat@graboxy.com">Contact us via email!</a></p>
              </div>
            </div>
          </form>
          <div className="thank-you" id="thank-you">
            <div className="thank-you__inner">
              <div className="thank-you__inner--icon">
                <img src={shield} alt="Shield" />
              </div>
              <div className="thank-you__inner--text">
                <h4>Thank You!</h4>
                <h4>We will get back to you <br />in the next 24 hours.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Form;
