import React from 'react';
import PropTypes from 'prop-types';

import styles from './Item.module.scss';

const Item = ({ title, children }) => (
  <div className={styles.Item}>
    <h5>{title}</h5>
    <p>{children}</p>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Item;
