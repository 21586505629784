import Section from '../Section';
import styles from './Hero.module.scss';

const Hero = () => (
  <Section id="hero" className={styles.Hero}>
    <div className="row">
      <div className="col-12 mb-3 text-center">
        <h1>Graboxy AntiCheat</h1>
        <h3>bust your opponents in CS:GO</h3>
      </div>

      <div className="col-12 col-xl-10 mx-auto">
        <p>
          Check if your enemies were using aimbots
          or they just had a really good day.
        </p>
        <p>
          We are building a third-party anticheat solution
          to eliminate aimbots in CS:GO.
          This is the first beta test open for public.
          All you need to do is submit a demo and we will evaluate the match.
        </p>
        <p>
          Since we have to manually download the DEM file you provide,
          you get the results via email within 60 minutes between
          09:00-18:00 BST on workdays. We will automate this in the future.
        </p>
      </div>
    </div>
  </Section>
);

export default Hero;
