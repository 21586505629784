import csgoSidebar from '../../images/csgo-sidebar.png';
import csgoDashboard from '../../images/csgo-dashboard.png';
import Section from '../Section';
import CsGoImg from './CsGoImg';
import './DemoLink.scss';

const DemoLink = () => (
  <Section
    id="link"
    title="How to find the in-Game demo link"
    className="DemoLink"
  >
    <div className="row how-container">
      <div className="col-lg-5">
        <p>
          1. Click on “Watch matches and tournaments"
          <br/>
          in the main menu
        </p>
      </div>
      <div className="col-lg-7">
        <p>2. Click on “Copy Match sharing code" on the bottom right</p>
      </div>
      <div className="img-container">
        <CsGoImg src={csgoSidebar} alt="CS:GO Sidebar" />
      </div>
      <div className="img-container">
        <CsGoImg src={csgoDashboard} alt="CS:GO Dashboard" />
      </div>
    </div>
  </Section>
);

export default DemoLink;
