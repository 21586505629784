import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import BackToHome from '../BackToHome';

import styles from './Document.module.scss';

const Document = ({ children }) => (
  <section>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <BackToHome />
        </div>

        <div className="col-12">
          <ReactMarkdown
            components={{ h1: 'h2' }}
            className={styles.Document}
          >
            {children}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  </section>
);

Document.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Document;
