import '@popperjs/core';
import 'bootstrap';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './styles/index.scss';
import App from './App';
import CookieConsent from './components/CookieConsent';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
}

TagManager.initialize(tagManagerArgs)

render(
  <StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <HashRouter>
        <App />
        <CookieConsent />
      </HashRouter>
    </GoogleReCaptchaProvider>
  </StrictMode>,
  document.getElementById('root')
);
