import { useHistory } from 'react-router';

import {
  ReactComponent as BackToHomeIcon,
} from '../../images/back-to-home.svg';

import './BackToHome.scss';

const BackToHome = () => {
  const history = useHistory();

  return (
    <div className="back-to-home">
      <button
        type="button"
        onClick={() => { history.push('/'); }}
      >
        <BackToHomeIcon />
        Back to home
      </button>
    </div>
  );
};

export default BackToHome;
