import React from 'react';
import PropTypes from 'prop-types';

import logoSrc from './logo.svg';

const Logo = ({ className, style }) => (
  <img
    src={logoSrc}
    alt="Graboxy AntiCheat Logo"
    className={className}
    style={style}
  />
);

Logo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

Logo.defaultProps = {
  className: undefined,
  style: undefined,
};

export default Logo;
