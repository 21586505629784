import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../components/Footer';

const BaseLayout = ({ children }) => (
  <>
    <main>{children}</main>
    <Footer />
  </>
);

BaseLayout.propTypes = {
  children: PropTypes.node,
};

BaseLayout.defaultProps = {
  children: undefined,
};

export default BaseLayout;

