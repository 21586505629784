import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import useScrollTop from './hooks/useScrollTop';
import BaseLayout from './layouts/BaseLayout';
import IndexPage from './pages/IndexPage';
import CookiePage from './pages/CookiePage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';

const App = () => {
  useScrollTop();

  return (
    <BaseLayout>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/cookie" component={CookiePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Redirect path="**" to="/" />
      </Switch>
    </BaseLayout>
  );
};

export default App;
