import React from 'react';
import PropTypes from 'prop-types';

import styles from './CsGoImg.module.scss';

const className = [
  'img-fluid',
  styles.CsGoImg,
].join(' ');

const CsGoImg = ({ src, alt }) => (
  <img src={src} alt={alt} className={className} />
);

CsGoImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CsGoImg;
