import Section from '../Section';
import Item from './Item';

const HowItWorks = () => (
  <Section
    id="how-it-works"
    title="How it works"
    className="HowItWorks"
  >
    <div className="row mt-3">
      <div className="col-12 col-xl-10 mx-auto">
        <Item title="1. Submit your demo">
          Make sure you send us your demo link in time.
          Matches are only available for download for
          6 days after the game was played.
          <br />
          <br />
          We will download the match with your link.
        </Item>

        <Item title="2. We run the analysis">
          We will evaluate the gameplay and see
          if anybody (including you 😉) was using aimbots.
        </Item>

        <Item title="3. You receive the results via email">
          Once the analysis is finished, we email you the results.
          We will not share your demo or the results with any third parties.
        </Item>
      </div>
    </div>
  </Section>
);

export default HowItWorks;
