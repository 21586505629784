import useMarkdown from '../../hooks/useMarkdown';
import BaseNavbar from '../../components/Navbar/BaseNavbar';
import Document from '../../components/Document';
import privacy from './privacy.md';

function Privacy() {
  const content = useMarkdown(privacy);

  return (
    <>
      <BaseNavbar />
      <Document>{content}</Document>
    </>
  );
}

export default Privacy;
