import useMarkdown from '../../hooks/useMarkdown';
import BaseNavbar from '../../components/Navbar/BaseNavbar';
import Document from '../../components/Document';
import cookie from './Cookie.md';

function Terms() {
  const content = useMarkdown(cookie);

  return (
    <>
      <BaseNavbar />
      <Document>{content}</Document>
    </>
  );
}

export default Terms;
