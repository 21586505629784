import FooterNav from './FooterNav';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.Footer}>
    <div className="container">
      <div className="row">
        <div className={`col-lg-8 ${styles.FooterLeft}`}>
          <FooterNav />
        </div>
        <div className={`col-lg-4 ${styles.FooterRight}`}>
          <span>
            © 2022,
            <a
              href="https://cursorinsight.com"
              target="_blank"
              rel="noreferrer">
              Cursor Insight Ltd.
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
