import React from 'react';
import PropTypes from 'prop-types';

const Toggler = ({ target }) => (
  <button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target={`#${target}`}
    aria-controls={target}
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon" />
  </button>
);

Toggler.propTypes = {
  target: PropTypes.string.isRequired,
};

export default Toggler;
