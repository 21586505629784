import React from 'react';
import { HashLink } from 'react-router-hash-link';

import Logo from '../Logo';
import Toggler from './Toggler';
import styles from './Navbar.module.scss';

import logosentinel from './logos/inverse-logo-graboxy-sentinel.svg';
import logo2fa from './logos/inverse-logo-graboxy-2fa.svg';
import logoanticheat from './logos/inverse-logo-graboxy-anticheat.svg';
import logocaptcha from './logos/inverse-logo-graboxy-captcha.svg';
import logocursor from './logos/inverse-logo-cursor-insight.svg';

const className = [
  'navbar fixed-top navbar-expand-lg navbar-dark',
  styles.Navbar,
].join(' ');

const BaseNavbar = () => (
  <header className="base-header">
    <nav className={className}>
      <div id="menuToggle" className="only-desktop">
        <input type="checkbox" />
        <span />
        <span />
        <span />
        <ul id="menu">
          <h3>Graboxy Products</h3>
          <li>
            <a
              href="https://sentinel.graboxy.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logosentinel} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://2fa.graboxy.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo2fa} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://anticheat.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoanticheat} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://captcha.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logocaptcha} alt="" />
            </a>
          </li>
          <h3>Company Website</h3>
          <li>
            <a
              href="https://cursorinsight.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logocursor} alt="Cursor Insight logo" />
            </a>
          </li>
        </ul>
      </div>
      <div className="container">
        <HashLink to="/#root" className="navbar-brand">
          <Logo className="img-fluid" style={{ maxHeight: 44 }} />
        </HashLink>

        <Toggler target="antiCheatNavbar" />

        <div className="collapse navbar-collapse" id="antiCheatNavbar">
          <ul className="navbar-nav ms-auto me-0 me-lg-4">
            <li className="nav-item mx-0 mx-lg-4">
              <HashLink to="/#how-it-works" className="nav-link">
                How it works
              </HashLink>
            </li>
            <li className="nav-item mx-0 mx-lg-4">
              <HashLink to="/#link" className="nav-link">
                How to find demo link
              </HashLink>
            </li>
          </ul>

          <form className="d-flex">
            <HashLink to="/#submit" className="btn">
              Submit Demo
            </HashLink>
          </form>

          <ul className="product-nav only-mobile">
            <h3>Graboxy Products</h3>
            <li>
              <a
                href="https://sentinel.graboxy.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logosentinel} alt="Graboxy Sentinel logo" />
              </a>
            </li>
            <li>
              <a
                href="https://2fa.graboxy.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logo2fa} alt="Graboxy 2FA logo" />
              </a>
            </li>
            <li>
              <a
                href="https://anticheat.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoanticheat} alt="Graboxy AntiCheat logo" />
              </a>
            </li>
            <li>
              <a
                href="https://captcha.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logocaptcha} alt="Graboxy CAPTCHA logo" />
              </a>
            </li>
            <h3 className="mt-1">Company Website</h3>
            <li>
              <a
                href="https://cursorinsight.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logocursor} alt="Cursor Insight logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
);

export default BaseNavbar;
